@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  src: url("./fonts/ProximaNovaSoftW03-Bold.ttf");
}

@font-face {
  font-family: "Proxima-Nova-Regular";
  src: url("./fonts/ProximaNovaSoftW03-Regular.ttf");
}
